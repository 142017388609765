import React, { useEffect, useRef, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import "../App.css";
import "../fire.scss";
import { AnimatePresence } from "framer-motion";
import Footer from "../Components/Footer";
import { TonConnectUIProvider } from "@tonconnect/ui-react";
import { UserProvider } from "../context/userContext";

const tele = window.Telegram.WebApp;
const Home = () => {
  const [loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    tele.ready();
    tele.expand();
    setTimeout(() => {
      setLoading(false);
    }, 3000);

    // window.Telegram.WebApp.setHeaderColor('#29162c'); // Set header color to red
    window.Telegram.WebApp.setHeaderColor("#1d0c45"); // Set header color to red
    window.Telegram.WebApp.disableVerticalSwipes();

    // Haptic feedback
    if (tele.HapticFeedback) {
      tele.HapticFeedback.impactOccurred("medium");
    }
    if (navigator.vibrate) {
      navigator.vibrate(100); // Vibrate for 100ms
    }
  }, []);

  return (
    <>
      <div className="w-full flex justify-center">
        {/* <div className="bg-[#efc26999] blur-[50px] select-none pointer-events-none absolute rotate-[35deg] w-[400px] h-[160px] -left-40 rounded-full"></div> */}

        <div className="w-full flex justify-center">
          <div className="flex flex-col pt-3 space-y-3 w-full">
            <TonConnectUIProvider manifestUrl="YOUR BOT APP URL/tonconnect-manifest.json">
              <UserProvider>
                <AnimatePresence mode="wait">
                  <Outlet />

                  <div
                    id="footermain"
                    className={`${
                      loading ? "invisible" : "visible"
                    } z-30 flex flex-col fixed bottom-0 py-6 left-0 right-0 justify-center items-center px-3`}
                  >
                    <Footer />

                    <div className="bg-[#000] z-20 h-[67px] w-full fixed bottom-0 left-0 right-0 "></div>
                  </div>
                </AnimatePresence>
              </UserProvider>
            </TonConnectUIProvider>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
