import React from "react";
import { NavLink, useLocation } from "react-router-dom";
import {
  PiUsersThreeFill,
  PiNotebookFill,
  PiRocketLaunchFill,
} from "react-icons/pi";
import { LuWallet } from "react-icons/lu";

const FooterLink = ({ title, link, icon: Icon }) => {
  const location = useLocation();
  const isActive = location.pathname === link;

  return (
    <NavLink
      key={title}
      to={link}
      className={`w-1/5 py-2 flex flex-col items-center justify-center rounded-[10px] ${
        isActive ? "text-white" : "text-[#c6c6c6]"
      }`}
    >
      <div
        className={`flex flex-col items-center space-y-1 ${
          isActive ? "text-white" : "text-[#949494]"
        }`}
      >
        {typeof Icon === "function" ? <Icon size={16} /> : Icon} {/* Icone più piccole */}
        <span className="text-xs font-medium">{title}</span>
      </div>
    </NavLink>
  );
};

const Footer = () => {
  const footerLinks = [
    {
      title: "Earn",
      link: "/",
      icon: (
        <img src="/logo.png" alt="Earn" className="w-4 pb-0.5  rounded-full" /> // Icona più piccola
      ),
    },
    {
      title: "Friends",
      link: "/ref",
      icon: PiUsersThreeFill,
    },
    {
      title: "Tasks",
      link: "/tasks",
      icon: PiNotebookFill,
    },
    {
      title: "Boost",
      link: "/boost",
      icon: PiRocketLaunchFill,
    },
    {
      title: "Airdrop",
      link: "/wallet",
      icon: LuWallet,
    },
  ];

  return (
    <footer className="w-max mx-auto px-6 z-30 flex gap-5 items-center h-[60px] justify-center bg-[#212121] border-[#363636] border border-b-[#2c2b2b] rounded-[35px]">
      {footerLinks.map((footer, index) => (
        <FooterLink key={index} {...footer} />
      ))}
    </footer>
  );
};

export default Footer;
