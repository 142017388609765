import React from 'react'
import ExternalTasks from '../Components/ExternalTasks'

const ExtrenalTasks = () => {
  return (


      <ExternalTasks/>
 //
  )
}

export default ExtrenalTasks