import React, { useState, useEffect } from 'react';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { db } from '../firebase/firestore';
import { useUser } from '../context/userContext';

export const Address = () => {
  let { id, setIsAddressSaved } = useUser();
  const [address, setAddress] = useState('');
  const [isSaved, setIsSaved] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const isValidAddress = (address) => {
    const solanaAddressRegex = /^[1-9A-HJ-NP-Za-km-z]{32,44}$/; // Regex per indirizzi Solana
    return solanaAddressRegex.test(address);
  };

  const fetchAddressFromFirestore = async () => {
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      const docSnap = await getDoc(userRef);
      if (docSnap.exists()) {
        const data = docSnap.data();
        setAddress(data.address || '');
        setIsSaved(data.isAddressSaved || false);
      } else {
        setError('User not found.');
      }
    } catch (err) {
      console.error('Error fetching address:', err);
      setError('Failed to fetch address. Please try again later.');
    } finally {
      setLoading(false);
    }
  };

  const modifyAddressInFirestore = async () => {
    if (!address.trim()) {
      setError('Please enter a valid Solana address.');
      setSuccess(null);
      return;
    }

    if (!isValidAddress(address)) {
      setError('The Solana address format is invalid.');
      setSuccess(null);
      return;
    }

    setLoading(true);
    const userRef = doc(db, 'telegramUsers', id.toString());
    try {
      await updateDoc(userRef, {
        address: address,
        isAddressSaved: true,
      });
      setIsAddressSaved(true);
      setIsSaved(true);
      setError(null);
      setSuccess('Address modified successfully!');
      setIsModalOpen(false); 
    } catch (err) {
      console.error('Error modifying address:', err);
      setError(`Failed to modify address. Error: ${err.message}`);
      setSuccess(null);
    } finally {
      setLoading(false);
    }
  };

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const getShortenedAddress = (address) => {
    if (!address) return '';
    const firstFive = address.slice(0, 5);
    const lastFive = address.slice(-5);
    return `${firstFive}...${lastFive}`;
  };

  useEffect(() => {
    fetchAddressFromFirestore();
  }, []);

  return (
    <div
      className="w-full max-w-md mx-auto shadow-lg rounded-lg p-6 transition-all duration-500 overflow-auto max-h-screen scale-9 -mt-6"
    >


      <h2 className="text-xl font-semibold text-whie text-center">
        {isSaved ? 'Your Solana Address' : 'Enter Your Solana Address'}
      </h2>
      <p className="text-lg text-white text-center mt-1">
        {isSaved
          ? 'Your address is successfully connected. You can update it below.'
          : 'Paste your Solana address below to connect it to your profile.'}
      </p>

      {loading ? (
        <div className="text-center mt-4 text-white">
          <div className="animate-pulse">Loading...</div>
        </div>
      ) : (
        <div className="mt-4">
          {isSaved ? (
            <div className="bg-gray-100 p-4 rounded-md shadow-md">
              <p className="text-center text-black text-base">Current Address:</p>
              <p className="text-center font-mono text-sm">{getShortenedAddress(address)}</p>
              <button
                onClick={openModal}
                className="w-full mt-4 text-sm sm:text-lg bg-green-600 hover:bg-green-700 text-white py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-150"
              >
                Update Address
              </button>
            </div>
          ) : (
            <div className="w-full">
              <input
                type="text"
                value={address}
                onChange={(e) => setAddress(e.target.value)}
                placeholder="Enter your Solana address"
                className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-black mb-4"
              />
              <button
                onClick={() => modifyAddressInFirestore()}
                disabled={loading}
                className={`w-full text-sm ${loading ? 'bg-gray-500' : 'bg-green-600 hover:bg-green-700'} py-2 rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 transition-all duration-150`}
              >
                {loading ? 'Saving...' : 'Connect Your Solana Wallet'}
              </button>
            </div>
          )}
        </div>
      )}

      {error && (
        <p className="text-red-500 text-sm sm:text-base mt-2 text-center">
          {error}
        </p>
      )}

      {success && (
        <p className="text-green-500 text-sm sm:text-base mt-2 text-center">
          {success}
        </p>
      )}

      {isModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-11/12 sm:w-96">
            <h3 className="text-xl text-black font-semibold mb-4">Update Your Solana Address</h3>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              placeholder="Enter new Solana address"
              className="w-full px-4 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-green-500 text-black mb-4"
            />
            <div className="flex justify-between">
              <button
                onClick={closeModal}
                className="text-sm text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={modifyAddressInFirestore}
                disabled={loading}
                className={`text-sm ${loading ? 'bg-gray-500' : 'bg-green-600 hover:bg-green-700'} py-2 px-4 rounded-md`}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
